* {
    padding: 0;
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

body, html {
    background: #a6e9ff;
}

.rc-tooltip {
    cursor: pointer;
}

a{
    color: black;
}

.rc-tooltip-hidden {
    display: none;
}

* {
    outline: none;
    -webkit-touch-callout:none;
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #a6e9ff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	transition: 0.5s;
  background: #3742fa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e67ff;
}